import http from "../Http-commons";

class Usuarios {
  listado(id) {
    return http.get(`/PaginaWeb/Representantes/${id}`);
  }
  Usuario(nombre) {
    return http.get(`/PaginaWeb/Usuarios/${nombre}`);
  }
  getid(id){
    return http.get(`/PaginaWeb/RepresentantesFormulario/${id}`);
  }
}

export default new Usuarios();
